import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { i18n } from "@/plugins/i18n";
import Vue from "vue";
import store from "@/store/index.js";
import { format, parseISO } from "date-fns";

export function generatePdf(empresa, orcamento) {
  return new Promise((resolve) => {
    let now = format(new Date(), "HH:mm:ss - dd/MM/yyyy");
    let nome = store.state.Usuario.usuario.name;

    let logo = {
      image: empresa.logo64,
      width: 150,
      alignment: "center",
      margin: [0, 0, 0, 25],
    };

    let orcamento_vencimento = {
      text:
        `${i18n.tc("global.vencimento").toUpperCase()}` +
          orcamento.condicao_pagamento ===
        2
          ? format(parseISO(orcamento.data_vencimento), "dd/MM/yyyy")
          : "",
      alignment: "right",
      fontSize: 8,
    };
    let dados = [
      {
        // margin: [left, top, right, bottom]
        margin: [0, 0, 0, 10],
        table: {
          widths: ["*", "*"],
          body: [
            [
              {
                margin: [5, 5, 5, 5],
                stack: [
                  {
                    text: i18n.tc("global.cliente").toUpperCase(),
                    alignment: "left",
                    // color: "#FFFFFF",
                    // fillColor: "#050505",
                    bold: true,
                    fontSize: 10,
                    margin: [0, 0, 0, 5],
                  },
                  {
                    stack: orcamento.cliente_id
                      ? [
                          {
                            text: orcamento.cliente_nome,
                            fontSize: 8,
                          },
                          {
                            text: orcamento.cliente_endereco
                              ? `${orcamento.cliente_endereco}, ${orcamento.cliente_cidade}`
                              : "",
                            fontSize: 8,
                          },
                          {
                            text: orcamento.cliente_ruc
                              ? orcamento.cliente_ruc
                              : orcamento.cliente_ci,
                            fontSize: 8,
                          },
                        ]
                      : [],
                  },
                ],
              },
              {
                margin: [5, 5, 5, 5],
                stack: [
                  {
                    text: `${i18n.tc("global.orcamento").toUpperCase()} Nº.: ${
                      orcamento.id
                    }`,
                    alignment: "right",
                    // color: "#FFFFFF",
                    // fillColor: "#050505",
                    bold: true,
                    fontSize: 10,
                    margin: [0, 0, 0, 5],
                  },
                  {
                    text: format(
                      parseISO(orcamento.created_at),
                      "dd/MM/yyyy - HH:mm:ss"
                    ),
                    alignment: "right",
                    fontSize: 8,
                  },
                  orcamento.condicao_pagamento === 2
                    ? orcamento_vencimento
                    : "",
                ],
              },
            ],
          ],
        },
        layout: {
          hLineWidth: function (i, node) {
            return i <= 1 || i === node.table.body.length ? 1 : 0;
          },
          // vLineWidth: function(i, node) {
          //   return i === 0 || i === node.table.widths.length ? 0 : 1;
          // },
          hLineColor: function (i, node) {
            return i === 0 || i === node.table.body.length
              ? "#666666"
              : "#666666";
          },
          vLineColor: function (i, node) {
            return i === 0 || i === node.table.widths.length
              ? "#666666"
              : "#666666";
          },
          fillColor: function (rowIndex, node, columnIndex) {
            return rowIndex > 0 && rowIndex % 2 === 0 ? "#F5F5F5" : null;
          },
        },
      },
    ];

    let tabela_items = {
      table: {
        widths: ["*", "auto", "auto", "auto"],
        headerRows: 1,
        body: [
          // Headers
          [
            {
              text: i18n.tc("global.produto").toUpperCase() + "(s)",
              fontSize: 8,
              fillColor: "#CFD8DC",
              // color: "#FFFFFF",
            },
            {
              text: "QTDE",
              alignment: "center",
              fontSize: 8,
              fillColor: "#CFD8DC",
              // color: "#FFFFFF",
            },
            {
              text: `${i18n.tc("global.preco").toUpperCase()} (${
                orcamento.moeda
              })`,
              fontSize: 8,
              fillColor: "#CFD8DC",
              // color: "#FFFFFF",
            },
            {
              text: "SUBTOTAL",
              fontSize: 8,
              fillColor: "#CFD8DC",
              // color: "#FFFFFF",
            },
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i <= 1 || i === node.table.body.length ? 1 : 0;
        },
        hLineColor: function (i, node) {
          return i === 0 || i === node.table.body.length
            ? "#666666"
            : "#666666";
        },
        vLineColor: function (i, node) {
          return i === 0 || i === node.table.widths.length
            ? "#666666"
            : "#666666";
        },
        fillColor: function (rowIndex, node, columnIndex) {
          return rowIndex > 0 && rowIndex % 2 === 0 ? "#F5F5F5" : null;
        },
      },
    };

    let subtotal_geral = 0;
    let desconto_geral = 0;

    orcamento.items.forEach((item) => {
      let obj = { ...item };

      let subtotal = Number(obj.qtde) * Number(obj.preco);
      subtotal_geral += subtotal;

      desconto_geral += obj.desconto ? obj.desconto : 0;
      if (orcamento.moeda) {
        obj.preco = Vue.filter("guarani")(Number(obj.preco));
        obj.subtotal = Vue.filter("guarani")(Number(subtotal));
      } else {
        obj.preco = Vue.filter("currency")(Number(obj.preco));
        obj.subtotal = Vue.filter("currency")(Number(subtotal));
      }

      let bodyLineFontSize = 8;
      let bodyLine = [
        { text: obj.descricao, fontSize: bodyLineFontSize },
        { text: obj.qtde, fontSize: bodyLineFontSize, alignment: "center" },
        { text: obj.preco, fontSize: bodyLineFontSize, alignment: "right" },
        { text: obj.subtotal, fontSize: bodyLineFontSize, alignment: "right" },
      ];

      tabela_items.table.body.push(bodyLine);
    });

    let total_geral = subtotal_geral - desconto_geral;

    if (orcamento.moeda == "G$") {
      subtotal_geral = Vue.filter("guarani")(Number(subtotal_geral));
      desconto_geral = Vue.filter("guarani")(Number(desconto_geral));
      total_geral = Vue.filter("guarani")(Number(total_geral));
    } else {
      subtotal_geral = Vue.filter("currency")(Number(subtotal_geral));
      desconto_geral = Vue.filter("currency")(Number(desconto_geral));
      total_geral = Vue.filter("currency")(Number(total_geral));
    }

    let tabela_total = {
      // margin: [left, top, right, bottom]
      margin: [0, 20, 0, 0],
      columns: [
        { width: "*", text: "" },
        {
          width: "auto",
          table: {
            widths: [75, 60],
            body: [
              [
                {
                  text: "SUBTOTAL",
                  alignment: "right",
                  fontSize: 8,
                  fillColor: "#CFD8DC",
                },
                {
                  text: subtotal_geral,
                  alignment: "right",
                  fontSize: 8,
                },
              ],
              [
                {
                  text: i18n.tc("global.desconto").toUpperCase(),
                  alignment: "right",
                  fontSize: 8,
                  fillColor: "#CFD8DC",
                },
                {
                  text: desconto_geral,
                  alignment: "right",
                  fontSize: 8,
                },
              ],
              [
                {
                  text: i18n.tc("global.total").toUpperCase(),
                  alignment: "right",
                  fontSize: 8,
                  fillColor: "#CFD8DC",
                },
                {
                  text: total_geral,
                  alignment: "right",
                  fontSize: 8,
                },
              ],
            ],
          },
          layout: {
            // hLineWidth: function(i, node) {
            //   return i <= 1 || i === node.table.body.length ? 1 : 0;
            // },
            hLineColor: function (i, node) {
              return i === 0 || i === node.table.body.length
                ? "#666666"
                : "#666666";
            },
            vLineColor: function (i, node) {
              return i === 0 || i === node.table.widths.length
                ? "#666666"
                : "#666666";
            },
            fillColor: function (rowIndex, node, columnIndex) {
              return rowIndex > 0 && rowIndex % 2 === 0 ? "#F5F5F5" : null;
            },
          },
        },
      ],
    };

    let obs = {
      stack: [
        {
          text: i18n.tc("global.observacao").toUpperCase() + ": ",
          fontSize: 9,
          bold: true,
          // margin: [left, top, right, bottom]
          margin: [0, 20, 0, 0],
        },
        {
          text: orcamento.obs,
          fontSize: 8,
        },
      ],
    };

    let forma_pagamento = {
      stack: [
        {
          text: i18n.tc("global.formapagamento").toUpperCase() + ": ",
          fontSize: 9,
          bold: true,
          // margin: [left, top, right, bottom]
          margin: [0, 20, 0, 0],
        },
        {
          text: orcamento.forma_pagamento,
          fontSize: 8,
        },
      ],
    };

    let docDefinition = {
      pageSize: "A4",
      pageMargins: [15, 15, 15, 15],
      pageOrientation: "portrait", //"portrait"
      defaultStyle: {
        font: "Roboto",
        columnGap: 20,
        lineHeight: 1.2,
      },
      info: {
        title: `Presupuesto_${orcamento.id}-${orcamento.cliente_nome}`,
        author: "john doe",
        subject: "subject of document",
        keywords: "keywords for document",
      },
      content: [
        empresa.logo64 ? logo : "",
        ...dados,
        tabela_items,
        tabela_total,
        orcamento.obs ? obs : "",
        orcamento.forma_pagamento ? forma_pagamento : "",
      ],
      footer(currentPage, pageCount) {
        return [
          {
            columns: [
              {
                text: `${nome} as ${now}`,
                fontSize: 7,
                margin: [15, 0, 0, 0],
                width: "25%",
              },
              {
                text: "SOFTPAR - SOFTWARE A MEDIDA DO SEU NEGÓCIO",
                fontSize: 7,
                alignment: "center",
                width: "*",
              },
              {
                text: `Pagina: ${currentPage.toString()} de ${pageCount}`,
                margin: [0, 0, 15, 0],
                alignment: "right",
                fontSize: 7,
                width: "25%",
              },
            ],
          },
        ];
      },
      styles: {
        header: {
          fontSize: 16,
          bold: true,
        },
        subheader: {
          fontSize: 10,
        },
      },
    };

    pdfMake.fonts = {
      Roboto: {
        normal: "Roboto-Regular.ttf",
        bold: "Roboto-Medium.ttf",
      },
    };
    let cliente_name = orcamento.cliente_nome.replace(" ", "_");
    let fileName = `Presupuesto_${orcamento.id}-${cliente_name}.pdf`;

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    // pdfDocGenerator.open();
    pdfDocGenerator.download(fileName);

    pdfDocGenerator.getDataUrl((data) => {
      resolve(data);
    });
  });
}
